import { decodeToken, isExpired } from "react-jwt";

export const saveTokenRefresh = (token) => {
  window.localStorage.setItem("dtknre", JSON.stringify(token));
};

export const setToken = (name, token) => {
  window.localStorage.setItem(name, JSON.stringify(token));
};

export const getTokenRefresh = () => {
  const tokenJson = window.localStorage.getItem("dtknre");

  if (!tokenJson) return null;

  const token = JSON.parse(tokenJson);
  return token;
};

export const getToken = (name) => {
  const tokenJson = window.localStorage.getItem(name);
  const token = JSON.parse(tokenJson);
  return token;
};

export const deleteToken = (name) => {
  window.localStorage.removeItem(name);
};

export const verifyExpireTokenRefresh = () => {
  const token = getTokenRefresh();

  if (!token) return true;

  const isTokenExpired = isExpired(token);
  return isTokenExpired;
};

export const decodeTokenRefresh = (token) => {
  const { uid } = decodeToken(token);
  return uid;
};

export const decodeDataToken = (token) => {
  const { name, lastname, email, phone } = decodeToken(token);
  return { name, lastname, email, phone };
};
