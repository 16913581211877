export const URL_LOGIN = "/api/v1/signin";

export const URL_REGISTER = "/api/v1/register";
export const URL_FORGOT_PASSWORD = "/api/v1/forgot-password";
export const URL_RE_SEND_EMAIL = "/api/v1/re-send-email";
export const URL_CONFIRM_EMAIL = "/api/v1/confirm-email";

export const URL_MODEL = "/api/v1/model";
export const URL_GENRES = "/api/v1/genres";
export const URL_RESET = "/api/v1/reset-password";

export const URL_DRIVER = "/api/v1/driver";
export const URL_CLIENT = "/api/v1/client";
export const URL_TRAVELLER = "/api/v1/traveller";
export const URL_VEHICLE = "/api/v1/vehicle";
export const URL_ADDRESS_LATLNG = "/api/v1/expand";

export const URL_BOOKING_NOW = "/api/v1/booking-now";
export const URL_BOOKING_BUSINESS = "/api/v1/booking-business";
//payments
export const URL_PAYMENTS = "/api/v1/driver-payments";
export const URL_TAX = "/api/v1/driver-tax";