import { URL_TAX } from "../../helpers/endpoint";
import { baseBackend } from ".././base/baseUrl";

export const getDriverTax = async (token, id) => {
  return await baseBackend.get(`${URL_TAX}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
