import { useEffect, useState } from "react";
import { Grid, TextField, Box, Avatar, Button, Stack } from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getDriverById, updateDriver } from "../../../api/driver";
import {
  decodeTokenRefresh,
  getTokenRefresh,
} from "../../../helpers/token/tokenRefresh";
import SnackBar from "../../../components/snackbar/SnackBar";

export const AccountProfileDetails = ({ isUpdate }) => {
  const [values, setValues] = useState("");
  const [dataUp, setDataUp] = useState("");

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleCloseSnk = () => {
    setStateSnk(false);
  };

  useEffect(() => {
    const getDriver = async () => {
      try {
        const token = getTokenRefresh();
        const id = decodeTokenRefresh(token);
        const response = await getDriverById(token, id);
        console.log(response.data);
        setValues(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getDriver();
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleUpdateDriver = async (e) => {
    e.preventDefault();
    const driver = {
      id: values.id,
      name: values.name,
      lastname: values.lastname,
      address: values.address.toUpperCase(),
      phone: values.phone,
      phone_reference: values.phone_reference,
      genres_id: values.genres_id,
    };
    const license = {
      number: values.license_number,
      expiration: values.license_expiration,
    };
    const contract = {
      number: values.contract_number,
      start: values.contract_start,
      end: values.contract_end,
    };
    const commission = {
      percentageValue: values.commission_percentage,
      fixedValue: values.commission_fixed,
    };

    try {
      const token = getTokenRefresh();
      const update = await updateDriver(
        token,
        driver,
        license,
        contract,
        commission
      );
      setMessageSnk("Datos Actualizados");
      setSeverity("success");
      setStateSnk(true);
    } catch (error) {
      setMessageSnk("Error: No se actualizo los datos");
      setSeverity("error");
      setStateSnk(true);
    }
  };

  return (
    <>
      {values ? (
        <form autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Avatar
                  sx={{
                    height: 64,
                    mb: 2,
                    width: 64,
                  }}
                />
              </Box>
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                value={values.name}
                fullWidth
                label="Name"
                name="name"
                onChange={handleChange}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                value={values.lastname}
                fullWidth
                label="Last name"
                name="lastname"
                onChange={handleChange}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                value={values.email}
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                value={values.address}
                fullWidth
                label="address"
                name="address"
                onChange={handleChange}
                disabled={isUpdate}
                inputProps={{style:{textTransform:"uppercase"}}}
                variant="outlined"
              />
            </Grid>

            <Grid item md={3} xs={12}>
              <TextField
                value={values.phone}
                fullWidth
                label="Phone"
                name="phone"
                onChange={handleChange}
                type="string"
                disabled={isUpdate}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={values.phone_reference}
                fullWidth
                label="Phone reference"
                name="phone_reference"
                onChange={handleChange}
                type="string"
                disabled={isUpdate}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={values.dni}
                fullWidth
                label="DNI"
                name="dni"
                onChange={handleChange}
                type="string"
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                value={values.genres_id}
                fullWidth
                label="Genre"
                name="country"
                onChange={handleChange}
                disabled={isUpdate}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                value={values.contract_number}
                fullWidth
                label="Contract"
                name="contract"
                onChange={handleChange}
                disabled
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <MobileDatePicker
                    value={values.contract_start}
                    label={"Inicio"}
                    inputFormat="DD/MM/YYYY"
                    name="contract_start"
                    onChange={(newValue) => {
                      values.contract_start = newValue;
                      console.log(newValue);
                      setDataUp(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    disabled
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <MobileDatePicker
                    value={values.contract_end}
                    label={"Inicio"}
                    inputFormat="DD/MM/YYYY"
                    name="contract_start"
                    onChange={(newValue) => {
                      values.contract_start = newValue;
                      console.log(newValue);
                      setDataUp(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    disabled
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="License"
                name="country"
                onChange={handleChange}
                disabled
                value={values.license_number}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <MobileDatePicker
                    value={values.license_expiration}
                    label={"Expira"}
                    inputFormat="DD/MM/YYYY"
                    name="license_expiration"
                    onChange={(newValue) => {
                      values.license_expiration = newValue;
                      console.log(newValue);
                      setDataUp(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={isUpdate}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} textAlign={"end"}>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isUpdate}
                onClick={handleUpdateDriver}
              >
                update
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={4000}
            handleClose={handleCloseSnk}
            severity={severity}
            messageSnk={messageSnk}
          />
        </form>
      ) : (
        <div>loading ...</div>
      )}
    </>
  );
};
