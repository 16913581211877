import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Header from "../../../../components/header/Header";
import Topbar from "../../../../components/topbar/TopBar";
import { getVehiclesByDriverId, updateVehicle } from "../../../../api/vehicle";
import {
  decodeTokenRefresh,
  getToken,
  getTokenRefresh,
} from "../../../../helpers/token/tokenRefresh";
import { tokens } from "../../../../theme";
import SnackBar from "../../../../components/snackbar/SnackBar";

const Vehicle = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isUpdate, setIsUpdate] = useState(true);
  const [dataUp, setDataUp] = useState("");
  const [vehicles, setVehicles] = useState([]);

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => {
    setStateSnk(false);
  };

  useEffect(() => {
    const getVehicle = async () => {
      try {
        const token = getTokenRefresh();
        const id = decodeTokenRefresh(token);
        const response = await getVehiclesByDriverId(token, id);
        setVehicles(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getVehicle();
  }, []);

  const handleChange = (event) => {
    setVehicles([...vehicles]);
    vehicles[0][event.target.name] = event.target.value;
  };

  const handleUpdateVehicle = async (data) => {
    console.log(data);
    try {
      const vehicle = {
        id: data.id,
        brand: data.brand,
        model: data.model,
        bodywork: data.bodywork,
        plate: data.plate,
        year: data.year,
        color: data.color,
        notes: data.notes,
      };
      const soat = {
        description: data.soat_description,
        start: data.soat_start,
        end: data.soat_end,
      };
      const rev_tech = {
        description: data.tr_description,
        start: data.tr_start,
        end: data.tr_end,
      };
      const setare = {
        permission: data.setare_description === 0 ? false : true,
        start: data.setare_start,
        end: data.setare_end,
      };
      const token = getToken("dtknre");
      const response = await updateVehicle(
        token,
        vehicle,
        soat,
        rev_tech,
        setare
      );
      snackBarDialog("VEHICULO ACTUALIZADO");
      console.log(response);
    } catch (error) {
      snackBarDialog("ERROR NO SE ACTUALIZO LOS DATOS", "error");
      console.log(error);
    }
  };

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  return (
    <div>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box >
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="VEHICULO" subtitle="" />
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="120px"
              gap="1px"
            >
              <Box gridColumn="span 12">
                {vehicles && vehicles.length > 0 ? (
                  vehicles.map((vehicle, index) => (
                    <Grid
                      key={index}
                      container
                      spacing={2}
                      sx={{ p: 1, alignItems: "center" }}
                      // border: "1px dashed grey"
                      // backgroundColor={colors.primary[400]}
                    >
                      <Grid item xs={12} md={12} textAlign="end">
                        <Button
                          sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                          }}
                          onClick={() => {
                            setIsUpdate(!isUpdate);
                          }}
                        >
                          <CreateOutlinedIcon sx={{ mr: "10px" }} />
                          Edit Vehicle
                        </Button>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          value={vehicle.brand}
                          fullWidth
                          label="Brand"
                          name="brand"
                          onChange={handleChange}
                          disabled={isUpdate}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          value={vehicle.model}
                          fullWidth
                          label="Model"
                          name="model"
                          onChange={handleChange}
                          disabled={isUpdate}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          value={vehicle.bodywork}
                          fullWidth
                          label="BodyWork"
                          name="bodyword"
                          onChange={handleChange}
                          disabled={isUpdate}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          value={vehicle.plate}
                          fullWidth
                          label="Plate"
                          name="plate"
                          onChange={handleChange}
                          type="string"
                          disabled={isUpdate}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          value={vehicle.year}
                          fullWidth
                          label="Year"
                          name="year"
                          onChange={handleChange}
                          disabled={isUpdate}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          value={vehicle.color}
                          fullWidth
                          label="Color"
                          name="color"
                          onChange={handleChange}
                          disabled={isUpdate}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          value={vehicle.soat_description}
                          fullWidth
                          label="SOAT"
                          name="soat_description"
                          onChange={handleChange}
                          disabled={isUpdate}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={2}>
                            <MobileDatePicker
                              value={vehicle.soat_start}
                              label={"FECHA DE INICIO"}
                              inputFormat="DD/MM/YYYY"
                              name="soat_start"
                              onChange={(newValue) => {
                                vehicle.soat_start = newValue.$d;
                                console.log(newValue);
                                setDataUp(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              disabled={isUpdate}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={2}>
                            <MobileDatePicker
                              value={vehicle.soat_end}
                              label={"FECHA DE EXPIRACION"}
                              inputFormat="DD/MM/YYYY"
                              name="soat_end"
                              onChange={(newValue) => {
                                vehicle.soat_end = newValue;
                                console.log(newValue);
                                setDataUp(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              disabled={isUpdate}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          value={vehicle.tr_description}
                          fullWidth
                          label="Revision Tecnica"
                          name="tr_description"
                          onChange={handleChange}
                          disabled={isUpdate}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={2}>
                            <MobileDatePicker
                              value={vehicle.tr_start}
                              label={"FECHA DE INICIO"}
                              inputFormat="DD/MM/YYYY"
                              name="tr_start"
                              onChange={(newValue) => {
                                vehicle.tr_start = newValue;
                                console.log(newValue);
                                setDataUp(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              disabled={isUpdate}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={2}>
                            <MobileDatePicker
                              value={vehicle.tr_end}
                              label={"FECHA DE EXPIRACION"}
                              inputFormat="DD/MM/YYYY"
                              name="tr_end"
                              onChange={(newValue) => {
                                vehicle.tr_end = newValue;
                                console.log(newValue);
                                setDataUp(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              disabled={isUpdate}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Typography>SETARE</Typography>
                      </Grid>
                      {vehicle.setare_description === 1 ? (
                        <>
                          <Grid item xs={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Stack spacing={2}>
                                <MobileDatePicker
                                  value={vehicle.setare_start}
                                  label={"FECHA INICIO"}
                                  inputFormat="DD/MM/YYYY"
                                  name="setare_start"
                                  onChange={(newValue) => {
                                    vehicle.setare_start = newValue;
                                    console.log(newValue);
                                    setDataUp(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  disabled={isUpdate}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Stack spacing={2}>
                                <MobileDatePicker
                                  value={vehicle.setare_end}
                                  label={"FECHA DE EXPIRACION"}
                                  inputFormat="DD/MM/YYYY"
                                  name="setare_end"
                                  onChange={(newValue) => {
                                    vehicle.setare_end = newValue;
                                    console.log(newValue);
                                    setDataUp(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  disabled={isUpdate}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={6} md={8}></Grid>
                      )}
                      <Grid item xs={12} sm={12} textAlign={"end"}>
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          disabled={isUpdate}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdateVehicle(vehicle);
                            //console.log(vehicle);
                          }}
                        >
                          update
                        </Button>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <div>loading</div>
                )}
              </Box>
            </Box>
            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleSnkClose}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </main>
      </div>
    </div>
  );
};

export default Vehicle;
