import { URL_BOOKING_BUSINESS } from "../../helpers/endpoint";
import { baseBackend } from "../base/baseUrl";

export const getBookingBusinessById = async (token, id) => {
  return await baseBackend.get(`${URL_BOOKING_BUSINESS}/driver/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
