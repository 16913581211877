import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { verifyEmail } from "../../../api/email/verifyEmail";

import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";

const ConfirmEmail = () => {
  const [verified, setVerified] = useState(null);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const validateEmail = async () => {
      try {
        await verifyEmail(token);
        setVerified("CUENTA VERIFICADA");
      } catch (error) {
        setVerified(error?.response?.data.error);
      }
    };
    validateEmail();
  }, []);

  const StyledContent = styled("div")(({ theme }) => ({
    maxWidth: "100%",
    margin: "auto",
    minHeight: "40vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(8, 0),
  }));

  return (
    // <>
    <Container>
      <StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
        {verified ? (
          <Typography variant="h3" paragraph>
            {verified}
          </Typography>
        ) : (
          <div>Loading... </div>
        )}

        <Box
          component="img"
          src="/assets/logo.png"
          sx={{ height: 140, mx: "auto", my: { xs: 5, sm: 5 } }}
        />

        <Button
          size="large"
          variant="contained"
          onClick={() => {
            navigate("/login");
          }}
        >
          INICIAR SESION
        </Button>
      </StyledContent>
    </Container>
    // </>
  );
};

export default ConfirmEmail;
