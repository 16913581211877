import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import Login from "./views/home/login";
import { ColorModeContext, useMode } from "./theme";
import ForgotPassword from "./views/home/forgot/Forgot";
import Dashboard from "./views/users/drivers/Dashboard";
import ResetPassword from "./views/home/forgot/ResetPassword";
import BookingNow from "./views/users/drivers/BookingNow";
import BookingLater from "./views/users/drivers/BookingLater";
import Vehicle from "./views/users/drivers/vehicle/Vehicle";
import ConfirmEmail from "./views/home/confirm/ConfirmEmail";
import Profile from "./views/users/drivers/profile/Profile";
import ListBookingNow from "./views/users/drivers/booking/ListBookingNow";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/">
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot" element={<ForgotPassword />} />
            <Route
              path="reset-password/:id/:token"
              element={<ResetPassword />}
            />
            <Route path="confirm-email/:token" element={<ConfirmEmail />} />
            <Route path="driver">
              <Route index element={<Dashboard />} />
              <Route path="profile" element={<Profile />} />
              <Route path="vehicle" element={<Vehicle />} />
              <Route path="list-booking-now" element={<ListBookingNow />} />
              <Route path="booking-now" element={<BookingNow />} />
              <Route path="booking-later" element={<BookingLater />} />

              <Route path="history/list-booking-now" element={<BookingLater />} />
              <Route path="history/list-booking-later" element={<BookingLater />} />
            </Route>
          </Route>
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
