import { Box, Typography, useTheme, Grid } from "@mui/material";
import { tokens } from "../../theme";

const StatBox = ({ title, subtitle, tax, total }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="2px" minWidth={280} backgroundColor={colors.primary[400]} p={1}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} sx={{ textAlign: "center" }}>
          <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
            Diario: {tax.fixedValue}
          </Typography>
          <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
            Porcentaje: {Number(tax.percentageValue)} %
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} sx={{ textAlign: "center" }}>
          <Typography variant="h5" fontWeight="600">
            {subtitle}
          </Typography>
          <Typography variant="h5" fontWeight="600">
            {Number(total)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatBox;
