import React from "react";
import { Box } from "@mui/material";
import StatBox from "../../../../components/statbox/StatBox";

const TransactionBox = ({ tax, total }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <StatBox
        title=""
        subtitle="TOTAL"
        tax={tax}
        total={Math.floor(total * 10) / 10}
      />
    </Box>
  );
};

export default TransactionBox;
