import { URL_DRIVER } from "../helpers/endpoint";
import { baseBackend } from "./base/baseUrl";

export const getDrivers = async (token) => {
  return await baseBackend.get(URL_DRIVER, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDriverById = async (token, id) => {
  return await baseBackend.get(`${URL_DRIVER}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateDriver = async (
  token,
  driver,
  license,
  contract,
  commission
) => {
  return await baseBackend.put(
    URL_DRIVER,
    JSON.stringify({
      driver,
      license,
      contract,
      commission,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const createdDriver = async (
  user,
  contract,
  license,
  driver,
  commissions,
  vehicles
) => {
  return await baseBackend.post(
    URL_DRIVER,
    JSON.stringify({
      user,
      contract,
      license,
      driver,
      commissions,
      vehicles,
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
