import React, { useState, useEffect } from "react";
import { CssBaseline, Box } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import Header from "../../../../components/header/Header";
import Topbar from "../../../../components/topbar/TopBar";
import BookingForm from "../../../../components/form/BookingForm";
import {
  getTokenRefresh,
  decodeTokenRefresh,
} from "../../../../helpers/token/tokenRefresh";
import { getBookingNowById } from "../../../../api/booking/getBookingById";
import { getBookingBusinessById } from "../../../../api/booking/getBookingBusiness";
import { convertDate } from "../../../../helpers/date/converDate";

const ListBookingNow = () => {
  const [booking, setBooking] = useState([]);
  const currency = {
    1: "PEN",
    2: "USD",
  };
  useEffect(() => {
    const getBookingNow = async () => {
      try {
        const token = getTokenRefresh();
        const uid = decodeTokenRefresh(token);
        console.log(uid);
        const response = await getBookingNowById(token, uid);
        const responseBusiness = await getBookingBusinessById(token, uid);
        //console.log(responseBusiness?.data);
        console.log(response?.data);
        //.sort(compareByDate)
        setBooking([]);
        Object.values(response?.data).map((book) => {
          book.uuid = uuidv4();
          book.fullnameClient = `${book.client_name} ${book.client_lastname}`;
          book.booking_type =
            book.booking_type === "NOW" ? "PEDIDO" : "RESERVA";
        });
        Object.values(responseBusiness?.data).map((book) => {
          book.uuid = uuidv4();
          book.fullnameClient = `${book.client_name} ${book.client_lastname}`;
          book.booking_type =
            book.booking_type === "NOW" ? "PEDIDO" : "RESERVA";
        });
        const compareByDate = (a, b) => {
          const dateB = convertDate(b.date, b.time);
          const dateA = convertDate(a.date, a.time);
          return dateB - dateA;
        };
  
        setBooking(
          [...response?.data, ...responseBusiness?.data].sort(compareByDate)
        );
      } catch (error) {
        console.log(error);
      }
    };
    getBookingNow();
  }, []);

  return (
    <div>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="Pedidos Asignados" subtitle="" />
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <BookingForm data={booking} />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};

export default ListBookingNow;
