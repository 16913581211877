import { URL_PAYMENTS } from "../../helpers/endpoint";
import { baseBackend } from ".././base/baseUrl";

export const getDriverPayments = async (token, id) => {
  return await baseBackend.get(`${URL_PAYMENTS}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
