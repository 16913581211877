import { URL_BOOKING_NOW } from "../../helpers/endpoint";
import { baseBackend } from "../base/baseUrl";

export const getBookingNowById = async (token, id) => {
  return await baseBackend.get(`${URL_BOOKING_NOW}/driver/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
