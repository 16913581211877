import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Translation = ({ backgroundColor }) => {
  const [lang, setLang] = useState("en");
  const { i18n } = useTranslation();
  const handleChange = (e) => {
    i18n.changeLanguage(e.target.value);
    setLang(e.target.value);
  };

  useEffect(() => {
    if (i18n.language.includes("es")) {
      i18n.changeLanguage("es");
      setLang("es");
    } else {
      i18n.changeLanguage("en");
      setLang("en");
    }
  }, []);

  return (
    <FormControl sx={{ m: 1, minWidth: 70 }}>
      <Select
        value={lang}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{ background: backgroundColor, height: 35, width: 70 }}
      >
        <MenuItem value={"en"}>
          <img height={15} width={20} src={`../../assets/us.svg`} />
        </MenuItem>
        <MenuItem value={"es"}>
          <img height={15} width={20} src={`../../assets/es.svg`} />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default Translation;
