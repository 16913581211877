import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { tokens } from "../../theme";
import { DataGridStyle } from "./BookingStyle";

const BookingForm = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      headerName: "Pedido",
      headerAlign: "center",
      field: "order_number",
      width: 50,
    },
    {
      headerName: "FECHA",
      field: "date",
      align: "left",
      width: 80,
    },
    {
      headerName: "HORA",
      field: "time",
      align: "left",
      width: 70,
    },
    {
      headerName: "Servicio",
      field: "status",
      width: 80,
      renderCell: ({ row: { status } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              textAlign: "center",
              color: "white",
              background: status === "PENDIENTE" ? "orange" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {status === "PENDIENTE" ? "PENDIENTE" : "REALIZADO"}
          </div>
        );
      },
    },
    {
      headerName: "USUARIO",
      field: "fullnameClient",
      align: "left",
      width: 150,
    },
    {
      headerName: "EMPRESA",
      field: "commertial_name",
      width: 150,
    },
    {
      headerName: "ORIGEN",
      field: "origin_name",
      headerAlign: "left",
      align: "left",
      width: 200,
      renderCell: ({
        row: { origin_name, origin_lat, origin_lng, origin_url },
      }) => {
        let url;
        if (!origin_url) {
          url = `https://www.google.com/maps/search/?api=1&query=${origin_lat},${origin_lng}&zoom=20`;
        } else {
          url = origin_url;
        }
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 14,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {origin_name}
          </a>
        );
      },
    },
    {
      headerName: "DESTINO",
      field: "destination_name",
      width: 200,
      renderCell: ({
        row: {
          destination_name,
          destination_lat,
          destination_lng,
          destination_url,
        },
      }) => {
        let url;
        if (!destination_url) {
          url = `https://www.google.com/maps/search/?api=1&query=${destination_lat},${destination_lng}&zoom=20`;
        } else {
          url = destination_url;
        }
        return (
          <a
            href={url}
            target="_blank"
            style={{
              color: "white",
              fontSize: 14,
              listStyle: "none",
              textDecoration: "none",
            }}
          >
            {destination_name}
          </a>
        );
      },
    },
    {
      headerName: "TIPO",
      field: "booking_type",
      width: 80,
    },
    {
      headerName: "PAGO",
      field: "payment_type",
      width: 80,
    },
    {
      headerName: "Moneda",
      field: "PEN",
      width: 80,
      renderCell: ({ row: { uuid } }) => {
        return (
          <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
            PEN
          </Typography>
        );
      },
    },
    {
      headerName: "Pago",
      field: "price_driver",
      width: 80,
    },
    {
      headerName: "Manifiesto",
      field: "manifest",
      width: 80,
      renderCell: ({ row: { uuid } }) => {
        return (
          <Button fullWidth color="secondary">
            <FindInPageIcon />
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              VER
            </Typography>
          </Button>
        );
      },
    },
    {
      headerName: "HOJA DE RUTA",
      field: "ruta",
      width: 100,
      renderCell: ({ row: { uuid } }) => {
        return (
          <Button fullWidth color="secondary">
            <FindInPageIcon />
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              VER
            </Typography>
          </Button>
        );
      },
    },
  ];

  return (
    <Box m="10px">
      <Box m="10px 0 0 0" height="100vh" sx={DataGridStyle(colors)}>
        <DataGrid getRowId={(row) => row.uuid} rows={data} columns={columns} />
      </Box>
    </Box>
  );
};

export default BookingForm;
