import { baseBackend } from "./base/baseUrl";
import {
  URL_LOGIN,
  URL_FORGOT_PASSWORD,
  URL_RE_SEND_EMAIL,
} from "../helpers/endpoint";

export const login = async (email, password) => {
  return await baseBackend.post(
    URL_LOGIN,
    JSON.stringify({ email, password, role: 3 }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const forgotPassword = async (email) => {
  return await baseBackend.post(
    URL_FORGOT_PASSWORD,
    JSON.stringify({ email, role: 3  }),
    {
      headers: { "Content-Type": "application/json" },
      //withCredentials: true,
    }
  );
};

export const reSendConfirmEmail = async (email, urlOrigin) => {
  return await baseBackend.post(
    URL_RE_SEND_EMAIL,
    JSON.stringify({ urlOrigin, email }),
    {
      headers: { "Content-Type": "application/json" },
      //withCredentials: true,
    }
  );
};
