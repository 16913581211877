import React from "react";
import { Box, Modal, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { dateFormat, timeFormat } from "../../../../helpers/date/date";
import TransactionDetail from "./TransactionDetail";

const Transaction = ({ title, data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [detail, setDetail] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (detail) => {
    setDetail(detail);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: colors.primary[400],
    border: "2px solid #000",
    boxShadow: 20,
    p: 1,
  };

  return (
    <Box height="100vh" backgroundColor={colors.primary[400]} overflow="auto">
      <Box
        borderBottom={`4px solid ${colors.primary[500]}`}
        colors={colors.grey[100]}
        p="10px"
      >
        <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
          {title}
        </Typography>
      </Box>
      {data.map((transaction, i) =>
        i < 100 ? (
          <Box
            key={i}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            p="10px"
            onClick={() => {
              transaction.order_number
                ? handleOpen(transaction)
                : console.log("no hace nada");
            }}
          >
            <Box>
              <Typography
                color={colors.greenAccent[500]}
                variant="h5"
                fontWeight="600"
              >
                {transaction.name && (
                  <>
                    {transaction.order_number} / {transaction.name}
                    {transaction.status === "PENDIENTE" ? (
                      <span
                        style={{
                          color: "red",
                          fontSize: 12,
                          fontStyle: "italic",
                        }}
                      >
                        - {transaction.status}
                      </span>
                    ) : null}
                  </>
                )}
                {transaction.fixed >= 0 && "COMISION FIJA"}
                {transaction.amount && transaction.amount >= 0 && "COBRADO"}
                {transaction.amount && transaction.amount < 0 && "PAGADO"}
              </Typography>
              <Typography color={colors.grey[100]}>
                {transaction.name
                  ? `${transaction.date} - ${transaction.time}`
                  : `${dateFormat(new Date(transaction.date))} -
                    ${timeFormat(new Date(transaction.date))}`}
              </Typography>
            </Box>
            <Box p="3px 3px">
              {transaction.name ? (
                transaction.name === "CREDITO" ? (
                  <>
                    <Typography color={"green"} variant="h5" fontWeight="600">
                      S/.{" "}
                      {transaction.price_driver -
                        transaction.price_driver *
                          (transaction.commission / 100)}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography color={"green"} variant="h5" fontWeight="600">
                      S/.{" "}
                      {Number(transaction.price_driver) -
                        Number(
                          transaction.price_driver *
                            (transaction.commission / 100)
                        )}
                    </Typography>
                  </>
                )
              ) : transaction.fixed >= 0 ? (
                <Typography color={"red"} variant="h5" fontWeight="600">
                  S/. {transaction.fixed}
                </Typography>
              ) : (
                <Typography
                  color={transaction.amount >= 0 ? "green" : "red"}
                  variant="h5"
                  fontWeight="600"
                >
                  S/. {Math.abs(transaction.amount)}
                </Typography>
              )}
            </Box>
          </Box>
        ) : null
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TransactionDetail detail={detail} />
        </Box>
      </Modal>
    </Box>
  );
};

export default Transaction;
// backgroundColor={colors.greenAccent[500]}
