import { useState } from "react";
import { Box, Button, useTheme, CssBaseline, Grid } from "@mui/material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { tokens } from "../../../../theme";
import Header from "../../../../components/header/Header";
import Topbar from "../../../../components/topbar/TopBar";
import { AccountProfileDetails } from "../account-profile-details";

const Profile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isUpdate, setIsUpdate] = useState(true);

  return (
    <div>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="My Profile" subtitle="" />
              <Box>
                <Button
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                  onClick={() => setIsUpdate(!isUpdate)}
                >
                  <CreateOutlinedIcon sx={{ mr: "10px" }} />
                  Edit Profile
                </Button>
              </Box>
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="120px"
              gap="10px"
            >
              <Box gridColumn="span 12" gridRow="span 2">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={1}>
                    <Grid item lg={12} md={12} xs={12}>
                      <AccountProfileDetails isUpdate={isUpdate} />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};

export default Profile;
