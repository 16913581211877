import React from "react";
import { Box, Grid, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { dateFormat, timeFormat } from "../../../../helpers/date/date";

const TransactionDetail = ({ detail }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box maxHeight={400} backgroundColor={colors.primary[400]} overflow="auto">
      <Box
        borderBottom={`4px solid ${colors.primary[500]}`}
        colors={colors.grey[100]}
        p="5px"
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              DETALLES
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              color={colors.grey[100]}
              variant="h5"
              fontWeight="600"
              textAlign={"end"}
            >
              {Number(detail.commission)} %
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={1}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              value={detail.order_number}
              label="PEDIDO:"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={detail.name}
              label="TIPO"
              fullWidth
              variant="outlined"
            />
          </Grid>
          {detail.name === "CREDITO" ? null : (
            <Grid item xs={6}>
              <TextField
                value={detail.tax}
                label="IMPUESTO"
                fullWidth
                variant="outlined"
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <TextField
              value={detail.toll}
              label="PEAJE"
              fullWidth
              variant="outlined"
            />
          </Grid>
          {detail.name === "CREDITO" ? null : (
            <Grid item xs={6}>
              <TextField
                value={detail.price_total}
                label="PRECIO TOTAL"
                fullWidth
                variant="outlined"
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <TextField
              value={detail.price_driver}
              label="PRECIO DE CONDUCTOR"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TransactionDetail;
// backgroundColor={colors.greenAccent[500]}
