import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  TableBody,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import { createTraveller } from "../../../api/traveller";
import { useTheme, CssBaseline } from "@mui/material";
import { tokens } from "../../../theme";

import Header from "../../../components/header/Header";
import Sidebar from "../../../components/sidebar/DriverSidebar";
import Topbar from "../../../components/topbar/TopBar";

const BookingNow = () => {
  //const theme = createTheme();
  const { t } = useTranslation();
  const [traveller, setTraveller] = useState(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    age: yup.number().min(1).required("required"),
    country: yup.string().required("required"),
    dni: yup.string().required("required"),
  });

  const initialValues = {
    name: "",
    age: "",
    country: "",
    dni: "",
  };

  // useEffect(() => {
  //   const travellers = async () => {
  //     const tokenJson = window.localStorage.getItem("loggedUser");
  //     if (tokenJson) {
  //       const token = JSON.parse(tokenJson);
  //       try {
  //         const response = await getTravellers(token);
  //         const data = response.data.travellers.filter(
  //           (traveller) => traveller.id
  //         );
  //         setTraveller(data);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   };

  //   travellers();
  // }, []);

  const handleFormSubmit = async (values) => {
    //e.preventDefault();
    try {
      const tokenJson = window.localStorage.getItem("loggedUser");
      if (tokenJson) {
        const token = JSON.parse(tokenJson);
        console.log(values);
        const response = await createTraveller(
          token,
          values.name,
          values.age,
          values.dni,
          values.country
        );
        console.log(response?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <div>
      <CssBaseline />
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="History Booking Now" subtitle="" />
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="120px"
              gap="10px"
            >
              <Box gridColumn="span 12" gridRow="span 2">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Origin</StyledTableCell>
                        <StyledTableCell>Destination</StyledTableCell>
                        <StyledTableCell>Vehicle</StyledTableCell>
                        <StyledTableCell>N Persons</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {traveller.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.age}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.document}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.country}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <button>Edit</button>
                        <button>Delete</button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))} */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};

export default BookingNow;
