import { URL_VEHICLE } from "../helpers/endpoint";
import { baseBackend } from "./base/baseUrl";

export const getVehicles = async (token) => {
  return await baseBackend.get(URL_VEHICLE, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getVehiclesByDriverId = async (token, id) => {
  return await baseBackend.get(`${URL_VEHICLE}/driver/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createVehicle = async (
  token,
  brand,
  model,
  bodywork,
  plate,
  year,
  color
) => {
  return await baseBackend.post(
    URL_VEHICLE,
    JSON.stringify({
      brand,
      model,
      bodywork,
      plate,
      year,
      color,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateVehicle = async (
  token,
  vehicle,
  soat,
  technical_review,
  setare
) => {
  return await baseBackend.put(
    URL_VEHICLE,
    JSON.stringify({
      vehicle,
      soat,
      technical_review,
      setare,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
