import { useEffect, useState } from "react";
import { Box, CssBaseline, Grid } from "@mui/material";
import Header from "../../../components/header/Header";
import Topbar from "../../../components/topbar/TopBar";
import Transaction from "./transactions/Transaction";
import TransactionBox from "./transactions/TransactionBox";
import {
  decodeTokenRefresh,
  getTokenRefresh,
} from "../../../helpers/token/tokenRefresh";
import { getDriverPayments } from "../../../api/driver/payments";
import { getDriverTax } from "../../../api/driver/tax";

const Dashboard = () => {
  const [payments, setPayments] = useState([]);
  const [tax, setTax] = useState(null);
  const [total, setTotal] = useState(null);

  //HISTORIAL DE PAGOS
  useEffect(() => {
    const getPayments = async () => {
      try {
        const token = getTokenRefresh();
        const id = decodeTokenRefresh(token);
        const response = await getDriverPayments(token, id);
        setPayments([]);
        setTotal(response.data.total);
        response.data.paymentList.map((payment) => {
          setPayments((oldData) => [...oldData, payment]);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getPayments();
  }, []);

  //COMISIONES
  useEffect(() => {
    const getTax = async () => {
      try {
        const token = getTokenRefresh();
        const id = decodeTokenRefresh(token);
        const response = await getDriverTax(token, id);
        setTax(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    getTax();
  }, []);

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Grid container alignItems="center" justify="center" spacing={1}>
              <Grid item xs={12} sm={12}>
                {tax ? <TransactionBox tax={tax} total={total} /> : null}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Transaction title={"HISTORIAL"} data={payments} />
              </Grid>
            </Grid>
          </Box>
        </main>
      </div>
    </>
  );
};

export default Dashboard;
